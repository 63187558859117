@import url("https://fonts.googleapis.com/css2?family=Outfit:wght@100..900&display=swap");
* {
  padding: 0;
  margin: 0;
  text-decoration: none !important;
  box-sizing: border-box;
  font-family: "Outfit", sans-serif !important;
  font-display: swap;
  list-style: none;
  scroll-behavior: smooth !important;
}
/* ===============================================scrollbar====================================== */
::-webkit-scrollbar {
  width: 8px;
}
::-webkit-scrollbar-track {
  box-shadow: inset 0 0 5px rgb(29, 121, 187);
  border-radius: 10px;
}
::-webkit-scrollbar-thumb {
  background: rgb(29, 121, 187);
  border-radius: 10px;
}
.slick-slide:focus,
.slick-list:focus,
.slick-slider img {
  outline: none !important;
  border: none !important;
}
/* ====================================================back-to-top============================= */
.back_to_top {
  height: 40px;
  width: 40px;
  box-shadow: 2px 5px 7px rgb(29, 121, 187);
  z-index: 20;
  border-radius: 50%;
  border: 1px solid white;
  bottom: 1%;
  right: 4px;
  animation: moves 1s alternate infinite;
}
.updown_ani {
  animation: updowm 2s ease-in-out infinite;
}
@keyframes moves {
  0%,
  100% {
    transform: translateY(0);
  }
  50% {
    transform: translateY(-4px);
  }
}
@keyframes updowm {
  0%,
  100% {
    transform: translateY(0);
  }
  50% {
    transform: translateY(-10px);
  }
}
.arrow {
  left: 170px;
  animation: arrow 5s linear infinite;
}
@keyframes arrow {
  0%,
  100% {
    transform: rotate(0deg);
  }
  50% {
    transform: rotate(-15deg);
  }
}
/* ===============================================Root=========================================== */
:root {
  --fs-6sm: 16px;
  --fs-8sm: 18px;
  --fs-md: 20px;
  --fs-4md: 24px;
  --fs-6md: 26px;
  --fs-2lg: 32px;
  --fs-2xl: 42px;
  --fs-4xl: 44px;
  --fs-8xl: 46px;
  --fs-xxl: 50px;
}
.fs_6sm {
  font-size: var(--fs-6sm);
}
.fs_8sm {
  font-size: var(--fs-8sm);
}
.fs_md {
  font-size: var(--fs-md);
}
.fs_4md {
  font-size: var(--fs-4md);
}
.fs_6md {
  font-size: var(--fs-6md);
}
.fs_2lg {
  font-size: var(--fs-2lg);
}
.fs_2xl {
  font-size: var(--fs-2xl);
}
.fs_4xl {
  font-size: var(--fs-4xl);
}
.fs_8xl {
  font-size: var(--fs-8xl);
}
.fs_xxl {
  font-size: var(--fs-xxl);
}
/* ===========================================Common-classes===================================== */
.transition {
  transition: all 0.3s linear !important;
}
.bg_sky {
  background: #1d79bb;
}
.text_sky {
  color: rgb(29, 121, 187);
}
.bg_gray {
  background: rgb(245, 245, 245);
}
.cursor_pointer {
  cursor: pointer;
}
.z_4 {
  z-index: 4;
}
.common_btn {
  background-color: rgb(29, 121, 187);
  border: 2px solid transparent;
}
.bg_skyblue {
  background: #e0ecfc;
}
.common_btn:hover {
  animation: pulse 1s;
  box-shadow: 0 0 0 0.7em transparent;
}
@keyframes pulse {
  0% {
    box-shadow: 0 0 0 0 rgb(29, 121, 187);
  }
}
.learn_btn {
  color: #1d79bb;
  border: 2px solid white;
}
.learn_btn:hover {
  animation: ani 1s;
  box-shadow: 0 0 0 0.7em transparent;
}
@keyframes ani {
  0% {
    box-shadow: 0 0 0 0 white;
  }
}
.letter_spacing {
  letter-spacing: 0.03em;
}
/* ===========================================NavBar-Section=================================== */
.nav_link:hover,
.under_line.hover .nav_link {
  color: rgb(29, 121, 187) !important;
}
.under_line::after {
  position: absolute;
  content: "";
  bottom: 0px;
  margin: 0 auto;
  left: 0;
  right: 0;
  width: 0;
  height: 2px;
  border-radius: 3px;
  background-color: rgb(29, 121, 187);
  transition: width 0.3s linear;
}
.under_line:hover::after,
.under_line.hover::after {
  width: 100%;
}
.crl_1,
.crl_2,
.crl_3 {
  background-color: rgb(29, 121, 187);
  width: 30px;
  height: 3px;
}
.max-w-1920 {
  max-width: 1920px;
}
/* ---------------------------------------------HOME-PAGe------------------------------------ */
.bg_hero {
  background-image: url("https://pdpl-stuff.s3.ap-south-1.amazonaws.com/dynamic/ksshospitals.com/ZMRVzHFz5I.webp");
  background-repeat: no-repeat;
  background-size: cover;
  background-position: center;
}
.slick-dots {
  display: flex !important;
  justify-content: center !important;
  align-items: center !important;
  gap: 4px !important;
  bottom: 16px !important;
}
.slick-dots li {
  width: 8px !important;
  height: 8px !important;
  background-color: rgb(188, 221, 245) !important;
  border-radius: 22px !important;
}
.slick-dots li.slick-active {
  width: 28px !important;
  height: 8px !important;
}
.slick-dots li.slick-active button:before {
  width: 28px !important;
  background-color: white !important;
  border-radius: 22px !important;
  opacity: 0.75 !important;
}
.slick-dots li button:before {
  content: "" !important;
  width: 8px !important;
  height: 8px !important;
}
.slick-slide.slick-active.slick-current div div.hover_active {
  border: 1px solid rgb(29, 121, 187) !important;
  background-color: #abd5eb !important;
  transform: scale(1.2);
  transition: transform 0.9s ease;
  position: relative;
  z-index: 2;
  filter: blur(0px);
  border-bottom-width: 5px !important;
  outline: none !important;
}
.custom_dots .slick-track {
  padding: 35px 0px !important;
}
.slick-slide div div.hover_active {
  border-radius: 18px !important;
  border: 1px solid rgb(164, 196, 219) !important;
  background-color: #dde3d1 !important;
  transform: scale(0.99);
  filter: blur(2px);
  outline: none !important;
  transform: all 900ms linear !important;
}
/* =======================================We-are-the-best===================================== */
.distributor_bg {
  background-image: url("https://pdpl-stuff.s3.ap-south-1.amazonaws.com/dynamic/ksshospitals.com/jv9SSmR1xC.svg");
}
.distributor_bg,
.bg_footer {
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
}
.flip-card-inner {
  transition: transform 0.8s;
  transform-style: preserve-3d;
  min-height: 260px;
  min-width: 220px;
}
.flip-card:hover .flip-card-inner {
  transform: rotateY(180deg);
}
.cardfrontside,
.cardbackside {
  -webkit-backface-visibility: hidden;
  backface-visibility: hidden;
}
.cardbackside {
  transform: rotateY(180deg);
  background-color: rgb(197, 229, 253);
}
/* =======================================ourPresence===================================== */
.max_w_500 {
  max-width: 550px;
}
/* ========================================Client-Reviews================================= */
.custom_dots .slick-slider .slick-dots {
  bottom: -20px !important;
}
.custom_dots .slick-slider .slick-dots li {
  width: 8px !important;
}
.custom_dots .slick-slider .slick-dots li.slick-active button:before {
  width: 8px !important;
  background-color: rgb(29, 121, 187) !important;
  opacity: 1 !important;
}
.review-card {
  min-height: 250px;
}
/* =======================================Footer============================================ */
.bg_footer {
  background-image: url("https://pdpl-stuff.s3.ap-south-1.amazonaws.com/dynamic/ksshospitals.com/oreSxAVnlw.svg");
  background-color: rgb(224, 236, 252);
}
.social_icon:hover {
  transform: translateY(-5px);
}
.contact_icon:hover > img {
  transform: scale(1.1);
}
/* -----------------------------------------ABOUT-US-------------------------------------------- */
.gray_layer {
  backdrop-filter: blur(30px);
  opacity: 0.8;
  background: rgb(245, 245, 245);
}
.hover-card:hover {
  transform: scale(1.01);
  border: 1px solid #1d79bb;
  box-shadow: 2px 10px 20px rgba(0, 0, 0, 0.1) !important;
}
.mt_neg_50 {
  margin-top: -115px;
}
/* ---------------------------------------------PARTNER-PAGE------------------------------------ */
.glass:hover {
  animation: tilt linear 2s infinite;
}
.partner_ani:hover > img {
  transform: scale(1.1);
}
@keyframes tilt {
  0%,
  100% {
    transform: skewY(2deg);
  }
  50% {
    transform: skewY(-2deg);
  }
}
.glass_effect:hover {
  transform: translateY(-12px);
}
.glass_effect::before {
  content: "";
  background: rgba(245, 243, 243, 0.4);
  transform: skew(45deg);
  position: absolute;
  width: 50px;
  height: 100%;
  left: -120%;
  transition: all 500ms ease-in-out;
  filter: blur(40px);
}
.glass_effect:hover::before {
  left: 150%;
}
.animation {
  animation: zoomInOut 1.5s infinite;
}
@keyframes zoomInOut {
  0%,
  100% {
    transform: scale(1);
  }
  50% {
    transform: scale(1.2);
  }
}
.list_dots li:before {
  background: black;
  width: 7px;
  height: 7px;
  border-radius: 50%;
  position: absolute;
  left: -20px;
  top: 10px;
  content: "";
}
/* -------------------------------------CONTACT-US----------------------------------------- */
.contact_input {
  border: 2px solid #025066;
  padding: 14px;
  font-size: 14px !important;
  outline: none !important;
  resize: none !important;
}
.mb_neg_7 {
  margin-bottom: -7px;
}
.logo_bg {
  background-image: url("https://pdpl-stuff.s3.ap-south-1.amazonaws.com/dynamic/ksshospitals.com/fKPsWefJKk.svg");
  background-repeat: no-repeat;
  background-position: center;
  background-size: 100% 100%;
}
/* =======================================media-quaries===================================== */
@media (min-width: 1200px) {
  .text_justify {
    text-align: justify;
  }
}
@media (max-width: 1200px) {
  .nav_bar {
    position: fixed;
    z-index: 4 !important;
    background: #abd5eb;
    min-height: 100vh;
    top: 0 !important;
    width: 70%;
    padding-top: 100px;
  }
  .navline span {
    width: 30px;
    height: 3px;
    background-color: #0056b3;
  }
  .nav_bar li {
    width: 100%;
    padding: 0px 24px;
    border-bottom: 1px solid white;
  }
  .left_neg_100 {
    right: -100%;
  }
  .navline span {
    width: 40px;
    height: 4px;
  }
  .review-card {
    min-height: 320px;
  }
  .Nav_overlay {
    position: absolute;
    width: 100%;
    height: 100%;
    background-color: rgba(0, 0, 0, 0.6);
    display: flex;
    align-items: center;
    justify-content: center;
    opacity: 0;
    transition: opacity 0.3s linear;
  }
  .Nav_overlay {
    opacity: 1;
  }
}
@media (max-width: 992px) {
  :root {
    --fs-xxl: 50px;
  }
  .mt_neg_50 {
    margin-top: 48px;
  }
  .slick-slide.slick-active.slick-current div div.hover_active {
    transform: scale(1) !important;
  }
}
@media (max-width: 768px) {
  :root {
    --fs-2xl: 38px;
    --fs-4xl: 42px;
    --fs-8xl: 44px;
    --fs-xxl: 50px;
  }
  .review-card {
    min-height: 250px;
  }
  .custom_dots .slick-track {
    padding: 0px !important;
  }
}
@media (max-width: 576px) {
  :root {
    --fs-2xl: 36px;
    --fs-4xl: 40px;
    --fs-8xl: 42px;
    --fs-xxl: 48px;
  }
  .crl_1,
  .crl_2,
  .crl_3 {
    background-color: rgb(29, 121, 187);
    width: 26px !important;
    height: 3px;
  }
}
@media (max-width: 425px) {
  :root {
    --fs-6sm: 12px;
    --fs-8sm: 14px;
    --fs-md: 16px;
    --fs-4md: 18px;
    --fs-2lg: 20px;
    --fs-2xl: 24px;
    --fs-4xl: 28px;
    --fs-8xl: 30px;
    --fs-xxl: 32px;
  }
}
.location-name {
  display: none;
  font-size: 14px;
  background-color: rgba(255, 255, 255, 0.9);
  padding: 2px 8px;
  border-radius: 4px;
  top: -25px;
  left: 50%;
  transform: translateX(-50%);
  z-index: 1;
}
.location-wrapper:hover .location-name {
  display: block;
}
.contact-popup {
  border-radius: 15px;
  box-shadow: 0 10px 20px rgba(0, 0, 0, 0.2);
  padding: 20px;
  animation: fadeIn 0.5s ease;
}
.fade-in {
  animation: fadeIn 0.5s ease;
}
@keyframes fadeIn {
  from {
    opacity: 0;
    transform: scale(0.9);
  }
  to {
    opacity: 1;
    transform: scale(1);
  }
}
